.we-offer-div{
    width: 450px;
    background: #fff; 
    border-radius: 8px;
    padding-bottom: 16px;
    box-sizing: border-box;
    cursor: pointer;
}
.we-offer-div:hover{
    transform: scale(1.1);
    transition: all 300ms ease-in-out;
}
@media (max-width: 1820px){
    .we-offer-div{
        width: 400px;
    }
}
@media (max-width: 1520px){
    .we-offer-div{
        width: 350px;
    }
}
@media (max-width: 1320px){
    .we-offer-div{
        width: 300px;
    }
}
@media (max-width: 1120px){
    .we-offer-div{
        width: 250px;
    }
}
@media (max-width: 960px){
    .we-offer-div{
        width: 450px;
    }
}
@media (max-width: 620px){
    .we-offer-div{
        width: 370px;
    }
}
@media (max-width: 480px){
    .we-offer-div{
        width: 300px;
    }
}