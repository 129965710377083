*{
    box-sizing: border-box;
}
.gpr-platforms-main-div{
    width: 100vw;
    height: 550px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow-y: visible;
}
.gpr-upper-div{
    width: 100%;
    height: 300px;
    padding-top: 72px;
    background-color: #232C60;
}
.gpr-lower-div{
    bottom: 0;
    width: 100%;
    height: 300px;
    padding-top: 40px;
    background-color: transparent;
}
.gpr-platform-heading{
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-family: Oxanium-Bold;
    color: #fff;
    /* margin-top: 40px; */
    margin-bottom: 40px;
    text-transform: uppercase;
}
.gpr-circle-container{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    gap: 80px;
    top: 50%;
    transform: translateY(-50%);
}
.gpr-circle{
    position: relative;
    height: 250px;
    width: 250px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}
.platform-image{
    position: absolute;
}
#gpr-drone-image{
    /* top: 8%;
    left: 10%;
    transform: translate(-20%, -20%); */
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    height: 155px;
}
#gpr-rover-image{
    /* top: 15%;
    left: 40%;
    transform: translate(-30%, -30%); */
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    height: 150px;
}
#gpr-scanner-image{
    /* top: 15%;
    left: 40%;
    transform: translate(-30%, -30%); */
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    height: 150px;
}
.gpr-platform-name{
    position: absolute;
    font-size: 14px;
    font-family: Oxanium-ExtraBold;
    text-transform: uppercase;
    color: #232C60;
    /* bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%); */
    text-align: center;
}
#gpr-platform-drone{
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#gpr-platform-rover{
    bottom: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#gpr-platform-scanner{
    bottom: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media (max-width: 1020px){
    .gpr-circle-container{
        gap: 40px;
    }
    #gpr-drone-image{
        /* top: 8%;
        left: 10%;
        transform: translate(-20%, -20%); */
        height: 150px;
    }
    #gpr-rover-image{
        /* top: 15%;
        left: 40%;
        transform: translate(-30%, -30%); */
        height: 140px;
    }
    #gpr-scanner-image{
        /* top: 15%;
        left: 40%;
        transform: translate(-30%, -30%); */
        height: 140px;
    }
}
@media (max-width: 960px){
    .gpr-platform-heading{
        font-size: 32px;
    }
}
@media (max-width: 860px){
    /* .gpr-platforms-main-div{
        height: 400px;
    } */
    /* .gpr-upper-div{
        height: 250px;
    }
    .gpr-lower-div{
        height: 250px;
    } */
    .gpr-circle{
        height: 200px;
        width: 200px;
    }
    #gpr-drone-image{
        /* top: 8%;
        left: 10%;
        transform: translate(-20%, -20%); */
        top: 10%;
        height: 120px;
    }
    #gpr-rover-image{
        /* top: 15%;
        left: 40%;
        transform: translate(-30%, -30%); */
        height: 120px;
    }
    #gpr-scanner-image{
        /* top: 15%;
        left: 40%;
        transform: translate(-30%, -30%); */
        height: 120px;
    }
    .gpr-platform-name{
        font-size: 12px;
    }
}
@media (max-width: 720px){
    .gpr-platforms-main-div{
        height: auto;
    }
    .gpr-lower-div{
        height: auto;
    }
    .gpr-circle-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: center;
        gap: 40px;
        top: 50%;
        transform: translateY(-15%);
    }
    .gpr-circle{
        height: 250px;
        width: 250px;
    }
    #gpr-drone-image{
        /* top: 8%;
        left: 10%;
        transform: translate(-20%, -20%); */
        top: 15%;
        height: 160px;
    }
    #gpr-rover-image{
        /* top: 15%;
        left: 40%;
        transform: translate(-30%, -30%); */
        height: 170px;
    }
    #gpr-scanner-image{
        /* top: 15%;
        left: 40%;
        transform: translate(-30%, -30%); */
        height: 170px;
    }
    .gpr-platform-name{
        font-size: 12px;
    }
    #gpr-platform-drone{
        bottom: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    #gpr-platform-rover{
        bottom: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    #gpr-platform-scanner{
        bottom: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
@media (max-width: 600px){
    .gpr-platform-heading{
        font-size: 24px;
    }
}
@media (max-width: 480px){
    .gpr-circle{
        height: 200px;
        width: 200px;
    }
    #gpr-drone-image{
        /* top: 8%;
        left: 10%;
        transform: translate(-20%, -20%); */
        height: 130px;
    }
    #gpr-rover-image{
        /* top: 15%;
        left: 40%;
        transform: translate(-30%, -30%); */
        height: 130px;
    }
    #gpr-scanner-image{
        /* top: 15%;
        left: 40%;
        transform: translate(-30%, -30%); */
        height: 130px;
    }
    .gpr-platform-name{
        font-size: 12px;
    }
    #gpr-platform-drone{
        bottom: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    #gpr-platform-rover{
        bottom: 4%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    #gpr-platform-scanner{
        bottom: 2%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}