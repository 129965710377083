/*------------------------ remove default-margin ------------------------------*/
*{
  margin: 0;
}

/*----------------------- remove default-scrollbar ----------------------------*/
body::-webkit-scrollbar{
  display: none;  /* for chrome browser */
}
body{
  -ms-overflow-style: none; /* for edge browser */
}

/*--------------------- customize scrollbar inside body -----------------------*/
@media (max-width: 550px){
  .modal-scrollable-content::-webkit-scrollbar{
    display: contents;  
  }
}
.modal-scrollable-content::-webkit-scrollbar{
  width: 8px;
}
.modal-scrollable-content::-webkit-scrollbar-track{
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.modal-scrollable-content::-webkit-scrollbar-thumb{
  background: #232C60;
  border: 1px solid transparent;
  background-clip: content-box;
  height: 5%;
  border-radius: 10px;
}
.swiper {
  display: flex;
  overflow-x: visible;
  transition-property: transform;
  will-change: transform;
}

/*--------------------------------------- fonts -------------------------------*/

/* fonts used in v-0 */
/* @font-face {
  font-family: NulshockBd;
  src: url(../src/assets/fonts/NulshockBd.ttf);
}
@font-face {
  font-family: PromptBold;
  src: url(../src/assets/fonts/Prompt-ExtraBold.ttf);
}
@font-face {
  font-family: PromptBold1;
  src: url(../src/assets/fonts/prompt/Prompt-Bold.ttf);
}
@font-face {
  font-family: PromptSemiBold;
  src: url(../src/assets/fonts/prompt/Prompt-SemiBold.ttf);
}
@font-face {
  font-family: PromptMedium;
  src: url(../src/assets/fonts/prompt/Prompt-Medium.ttf);
}
@font-face {
  font-family: PromptRegular;
  src: url(../src/assets/fonts/prompt/Prompt-Regular.ttf);
}
@font-face {
  font-family: PromptThin;
  src: url(../src/assets/fonts/prompt/Prompt-Thin.ttf);
}
@font-face {
  font-family: PromptLight;
  src: url(../src/assets/fonts/prompt/Prompt-Light.ttf);
} */

/* -------------------fonts that will be used in v-1-------------------------- */

/* oxanium */
@font-face {
  font-family: Oxanium-Bold;
  src: url(../src/assetsv1/fonts/oxanium/static/Oxanium-Bold.ttf);
}
@font-face {
  font-family: Oxanium-ExtraBold;
  src: url(../src/assetsv1/fonts/oxanium/static/Oxanium-ExtraBold.ttf);
}
@font-face {
  font-family: Oxanium-ExtraLight;
  src: url(../src/assetsv1/fonts/oxanium/static/Oxanium-ExtraLight.ttf);
}
@font-face {
  font-family: Oxanium-Light;
  src: url(../src/assetsv1/fonts/oxanium/static/Oxanium-Light.ttf);
}
@font-face {
  font-family: Oxanium-Medium;
  src: url(../src/assetsv1/fonts/oxanium/static/Oxanium-Medium.ttf);
}
@font-face {
  font-family: Oxanium-Regular;
  src: url(../src/assetsv1/fonts/oxanium/static/Oxanium-Regular.ttf);
}
@font-face {
  font-family: Oxanium-SemiBold;
  src: url(../src/assetsv1/fonts/oxanium/static/Oxanium-SemiBold.ttf);
}

/* nunito sans 7pt */
@font-face {
  font-family: NunitoSans_7pt-Black;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-Black.ttf);
}
@font-face {
  font-family: NunitoSans_7pt-BlackItalic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-BlackItalic.ttf);
}
@font-face {
  font-family: NunitoSans_7pt-Bold;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-Bold.ttf);
}
@font-face {
  font-family: NunitoSans_7pt-BoldItalic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-BoldItalic.ttf);
}
@font-face {
  font-family: NunitoSans_7pt-ExtraBold;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-ExtraBold.ttf);
}
@font-face {
  font-family: NunitoSans_7pt-ExtraBoldItalic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-ExtraBoldItalic.ttf);
}
@font-face {
  font-family: NunitoSans_7pt-ExtraLight;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-ExtraLight.ttf);
}
@font-face {
  font-family: NunitoSans_7pt-ExtraLightItalic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-ExtraLightItalic.ttf);
}
@font-face {
  font-family: NunitoSans_7pt-Italic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-Italic.ttf);
}
@font-face {
  font-family: NunitoSans_7pt-Light;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-Light.ttf);
}
@font-face {
  font-family: NunitoSans_7pt-LightItalic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-LightItalic.ttf);
}
@font-face {
  font-family: NunitoSans_7pt-Medium;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-Medium.ttf);
}
@font-face {
  font-family: NunitoSans_7pt-MediumItalic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-MediumItalic.ttf);
}
@font-face {
  font-family: NunitoSans_7pt-Regular;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-Regular.ttf);
}
@font-face {
  font-family: NunitoSans_7pt-SemiBold;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-SemiBold.ttf);
}
@font-face {
  font-family: NunitoSans_7pt-SemiBoldItalic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_7pt-SemiBoldItalic.ttf);
}

/* nunito sans 10pt */
@font-face {
  font-family: NunitoSans_10pt-Black;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-Black.ttf);
}
@font-face {
  font-family: NunitoSans_10pt-BlackItalic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-BlackItalic.ttf);
}
@font-face {
  font-family: NunitoSans_10pt-Bold;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-Bold.ttf);
}
@font-face {
  font-family: NunitoSans_10pt-BoldItalic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-BoldItalic.ttf);
}
@font-face {
  font-family: NunitoSans_10pt-ExtraBold;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-ExtraBold.ttf);
}
@font-face {
  font-family: NunitoSans_10pt-ExtraBoldItalic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-ExtraBoldItalic.ttf);
}
@font-face {
  font-family: NunitoSans_10pt-ExtraLight;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-ExtraLight.ttf);
}
@font-face {
  font-family: NunitoSans_10pt-ExtraLightItalic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-ExtraLightItalic.ttf);
}
@font-face {
  font-family: NunitoSans_10pt-Italic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-Italic.ttf);
}
@font-face {
  font-family: NunitoSans_10pt-Light;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-Light.ttf);
}
@font-face {
  font-family: NunitoSans_10pt-LightItalic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-LightItalic.ttf);
}
@font-face {
  font-family: NunitoSans_10pt-Medium;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-Medium.ttf);
}
@font-face {
  font-family: NunitoSans_10pt-MediumItalic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-MediumItalic.ttf);
}
@font-face {
  font-family: NunitoSans_10pt-Regular;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-Regular.ttf);
}
@font-face {
  font-family: NunitoSans_10pt-SemiBold;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-SemiBold.ttf);
}
@font-face {
  font-family: NunitoSans_10pt-SemiBoldItalic;
  src: url(../src/assetsv1/fonts/nunitosans/static/NunitoSans_10pt-SemiBoldItalic.ttf);
}

/* dropdown menu for tech solutions */
.tech-item-container{
  position: relative;
}
.tech-dropdown-menu {
  box-sizing: border-box;
  display: none;
  flex-direction: column;
  position: absolute;
  top: 10;
  opacity: 0;
  height: auto;
  text-transform: uppercase;
  font-family: Oxanium-Bold;
  transition: 300ms ease-in-out all;
  background-color: #fff;
  color: #232C60;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  /* justify-content: space-evenly; */
  font-size: 12px;
  box-shadow: 0px 0px 2px 0px #000;
}
.drop-down-icon{
  transform: rotate(-90deg);
  transition: 300ms ease-in-out all;
}

@media(hover:hover){
  .tech-item-container:hover .drop-down-icon{
    transform: rotate(0deg);
  }
  
  .tech-item-container:hover .tech-dropdown-menu{
    opacity: 1;
    /* height: 141px; */
    display: flex;
  }
  .tech-dropdown-item:hover{
    background-color: #232C60;
    color: #fff;
  }
}
/* .tech-item-container:hover .drop-down-icon{
  transform: rotate(0deg);
}

.tech-item-container:hover .tech-dropdown-menu{
  opacity: 1;
  height: 141px;
} */
.tech-dropdown-item{
  padding: 16px;
}
/* .tech-dropdown-item:hover{
  background-color: #232C60;
  color: #fff;
} */
/* .tech-dropdown-item #sar{
  margin-top: 5px;
} */
