*{
    box-sizing: border-box;
}
.sar-platforms-main-div{
    width: 100vw;
    height: 550px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow-y: visible;
}
.sar-upper-div{
    width: 100%;
    height: 300px;
    padding-top: 72px;
    background-color: #232C60;
}
.sar-lower-div{
    bottom: 0;
    width: 100%;
    height: 300px;
    padding-top: 40px;
    background-color: transparent;
}
.sar-platform-heading{
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-family: Oxanium-Bold;
    color: #fff;
    /* margin-top: 40px; */
    margin-bottom: 72px;
    text-transform: uppercase;
}
.sar-circle-container{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    gap: 80px;
    top: 50%;
    transform: translateY(-50%);
}
.sar-circle{
    position: relative;
    height: 250px;
    width: 250px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}
.platform-image{
    position: absolute;
}
#sar-satellite-image{
    /* top: 8%;
    left: 10%;
    transform: translate(-20%, -20%); */
    /* top: 4%; */
    left: 50%;
    transform: translateX(-50%);
    height: 200px;
}
#sar-aeroplane-image{
    /* top: 8%;
    left: 5%;
    transform: translate(-30%, -30%); */
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    height: 130px;
}
#sar-drone-image{
    /* top: 20%;
    left: 5%;
    transform: translate(-30%, -30%); */
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    height: 95px;
}
.sar-platform-name{
    position: absolute;
    font-size: 14px;
    font-family: Oxanium-ExtraBold;
    text-transform: uppercase;
    color: #232C60;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 1020px){
    .sar-circle-container{
        gap: 40px;
    }
    /* #sar-satellite-image{
        top: 8%;
        left: 10%;
        transform: translate(-20%, -20%);
        height: 220px;
    }
    #sar-aeroplane-image{
        top: 15%;
        left: 10%;
        transform: translate(-30%, -30%);
        height: 150px;
    }
    #sar-drone-image{
        top: 20%;
        left: 5%;
        transform: translate(-30%, -30%);
        height: 130px;
    } */
}
@media(max-width: 960px){
    .sar-platform-heading{
        font-size: 32px;
    }  
}
@media (max-width: 860px){
    .sar-circle-container{
        gap: 40px;
    }
    .sar-circle{
        height: 200px;
        width: 200px;
    }
    #sar-satellite-image{
        /* top: 8%;
        left: 10%;
        transform: translate(-20%, -20%); */
        height: 160px;
    }
    #sar-aeroplane-image{
        /* top: 15%;
        left: 10%;
        transform: translate(-30%, -30%); */
        height: 100px;
    }
    #sar-drone-image{
        /* top: 20%;
        left: 5%;
        transform: translate(-30%, -30%); */
        height: 70px;
    }
    .sar-platform-name{
        font-size: 12px;
        /* bottom: 10%;
        left: 50%;
        transform: translate(-50%, -50%); */
    }
}


@media (max-width: 860px){
    /* .sar-platforms-main-div{
        height: 400px;
    }
    .sar-upper-div{
        height: 250px;
    }
    .sar-lower-div{
        height: 250px;
    }
    .sar-platform-heading{
        font-size: 32px;
    }
    .sar-circle{
        height: 150px;
        width: 150px;
    }
    #sar-satellite-image{
        top: 8%;
        left: 10%;
        transform: translate(-20%, -20%);
        height: 180px;
    }
    #sar-aeroplane-image{
        top: 15%;
        left: 10%;
        transform: translate(-30%, -30%);
        height: 100px;
    }
    #sar-drone-image{
        top: 20%;
        left: 5%;
        transform: translate(-30%, -30%);
        height: 100px;
    }
    .sar-platform-name{
        font-size: 12px;
    } */
}
@media (max-width: 720px){
    .sar-platforms-main-div{
        height: auto;
    }
    .sar-lower-div{
        height: auto;
    }
    .sar-circle-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: center;
        gap: 40px;
        top: 50%;
        transform: translateY(-15%);
    }
    .sar-circle{
        height: 250px;
        width: 250px;
    }
    #sar-satellite-image{
        /* top: 8%;
        left: 10%;
        transform: translate(-20%, -20%); */
        height: 210px;
    }
    #sar-aeroplane-image{
        /* top: 15%;
        left: 10%;
        transform: translate(-30%, -30%); */
        height: 130px;
    }
    #sar-drone-image{
        /* top: 20%;
        left: 5%;
        transform: translate(-30%, -30%); */
        height: 95px;
    }
    .sar-platform-name{
        font-size: 12px;
    }
}
@media(max-width: 600px){
    .sar-platform-heading{
        font-size: 24px;
    }  
}

@media (max-width: 480px){
    .sar-platforms-main-div{
        height: auto;
    }
    .sar-lower-div{
        height: auto;
    }
    .sar-circle{
        height: 200px;
        width: 200px;
    }
    #sar-satellite-image{
        /* top: 8%;
        left: 10%;
        transform: translate(-20%, -20%); */
        height: 160px;
    }
    #sar-aeroplane-image{
        /* top: 15%;
        left: 10%;
        transform: translate(-30%, -30%); */
        height: 100px;
    }
    #sar-drone-image{
        /* top: 20%;
        left: 5%;
        transform: translate(-30%, -30%); */
        height: 75px;
    }
    .sar-platform-name{
        font-size: 12px;
    }
}