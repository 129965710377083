.gpr-carousel-outer-div{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    background-color: #232C60;
    color: #fff;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 40px;
}
.gpr-carousel-heading-container{
    width: 100vw;
    text-align: center;
}
.gpr-carousel-heading{
    font-size: 40px;
    font-family: Oxanium-Bold;
    text-transform: uppercase;
}
.gpr-carousel-inner-div{
    display: flex;
    width: 100vw;
    height: auto;
    background-color: #232C60;
    color: #fff;
    gap: 40px;
    align-items: center;
    justify-content: center;
}
.gpr-carousel-prev-btn-container{
    width: 32px;
    height: 32px;
}
.gpr-carousel-next-btn-container{
    width: 32px;
    height: 32px;
}
.prev-btn{
    height: 100%;
}
.next-btn{
    height: 100%;
}
.gpr-carousel-div{
    display: flex;
    width: 850px;
    overflow-x: hidden;
    /* gap: 32px; */
}
.lg-child{
    width: 850px;
    /* height: 450px; */
    /* background-color: antiquewhite; */
    display: flex;
    gap: 32px;
    transition: translate 300ms ease-in-out;
}
.gpr-application{
    position: relative;
    width: calc(850px/3);
    height: 100%;
    /* background-color: #fff; */
    display: flex;
    flex-direction: column;
}
.gpr-application-img{
    width: 100%;
    border-bottom: solid 6px #fff;
}
.gpr-application-title{
    position: absolute;
    color: #fff;
    font-size: 18px;
    font-family: Oxanium-Bold;
    bottom: 5%;
    transform: translateY(-5%);
    margin-left: 16px;
}
.text-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.75);
    color: black;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  .gpr-application:hover .text-overlay{
    height: 100%;
  }
  .gpr-application:hover .gpr-application-title{
    color: transparent;
  }
  .text-overlay-heading{
    font-size: 16px;
    font-family: Oxanium-Bold;
    align-self: flex-start;
    margin: 16px;
    margin-top: 150px;
  }
  .text-overlay-description{
    font-size: 12px;
    font-family: NunitoSans_7pt-SemiBold;
    margin-right: 16px;
    margin-left: 16px;
  }
  @media (max-width: 1320px){
    .gpr-carousel-div{
        width: 750px;
    }
    .lg-child{
        width: 750px;
        height: 390px;
    }
    .gpr-application{
        width: calc(750px/3);
        height: 100%;
    }
    .text-overlay-heading{
        font-size: 14px;
      }
      .text-overlay-description{
        font-size: 11px;
      }
  }
  @media(max-width: 960px){
    .gpr-carousel-inner-div{
        gap: 20px;
    }
    .gpr-carousel-div{
        width: 700px;
        height: auto;
        display: flex;
        overflow-x: hidden;
    }
    .gpr-carousel-heading{
        font-size: 32px;
    }
    .lg-child{
        display: flex;
        width: 700px;
        height: auto;
        gap: 16px;
        transition: translate 300ms ease-in-out;
    }
    .gpr-application{
        position: unset;
        width: calc(((700-32)px)/3);
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .gpr-application-img{
        width: 100%;
        margin-bottom: 16px;
    }
    .gpr-application-title{
        display: none;
    }
    .text-overlay{
        position: unset;
        bottom: unset;
        left: unset;
        right: unset;
        display: flex;
        flex-direction: column;
        color: #232C60;
        width: calc(((700-32)px)/3);
        height: unset;
    }
    .text-overlay-heading{
        font-family: Oxanium-Bold;
        font-size: 16px;
        margin-top: unset;
        /* margin-left: 24px; */
    }
    .text-overlay-description{
        font-family: NunitoSans_7pt-SemiBold;
        font-size: 12px;
        margin-bottom: 16px;
        margin-right: 12px;
    }
  }
  @media (max-width: 840px){
    .gpr-carousel-div-1{
        display: flex;
        width: 380px;
        overflow: hidden;
    }
    .lg-child-1{
        display: flex;
        gap: 16px;
        width: 380px;
        height: auto;
        gap: 0px;
        transition: translate 300ms ease-in-out;
    }
    .gpr-application-1{
        display: flex;
        flex-direction: column;
        /* position: relative; */
        width: 380px;
        height: 100%;
        background-color: #fff;
    }
    .gpr-application-img-1{
        width: 380px;
    }
    .text-overlay-1 {
        background-color: #fff;
        color: #232C60;
        overflow: hidden;
        width: 100%;
        height: auto;
        transition: 0.5s ease;
        display: flex;
        flex-direction: column;
      }
      .text-overlay-heading-1{
        font-size: 18px;
        font-family: Oxanium-Bold;
        align-self: flex-start;
        margin: 18px;
      }
      .text-overlay-description-1{
        font-size: 14px;
        font-family: NunitoSans_7pt-SemiBold;
        margin-right: 12px;
        margin-bottom: 16px;
        margin-left: 18px;
      }
  }
  @media (max-width: 640px){
    .gpr-carousel-div-1{
        width: 320px;
    }
    .lg-child-1{
        width: 320px;
        height: auto;
    }
    .gpr-application-1{
        width: 320px;
        height: 100%;
    }
    .gpr-application-img-1{
        width: 320px;
    }
      /* .text-overlay-heading-1{
        font-size: 18px;
      }
      .text-overlay-description-1{
        font-size: 14px;
      } */
  }
  @media(max-width: 640px){
    .gpr-carousel-heading{
        font-size: 24px;
    }
}
  @media (max-width: 460px){
    .gpr-carousel-div-1{
        width: 65vw;
    }
    .lg-child-1{
        width: 65vw;
        height: auto;
    }
    .gpr-application-1{
        width: 65vw;
        height: 100%;
    }
    .gpr-application-img-1{
        width: 65vw;
    }
      /* .text-overlay-heading-1{
        font-size: 24px;
      }
      .text-overlay-description-1{
        font-size: 12px;
      } */
  }
 