*{
    box-sizing: border-box;
}
.timeline-wrapper-lg{
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding: 96px 40px;
    /* padding-left: 40px;
    padding-top:  350px;
    padding-right: 40px;
    padding-bottom: 200px; */
    align-items: center;
    justify-content: center;
    gap: 72px;
}
.timeline-wrapper-lg h1{
    font-size: 40px;
    font-family: Oxanium-Bold;
    color: #232C60;
    text-transform: uppercase;
}
.timeline-carousel-container-lg{
    width: 1080px;
    height: 600px;
    background-color: #232C60;
    display: flex;
    align-items: center;
    justify-content: center;
}
.timeline-indicator-container-lg{
    height: 450px;
    width: 40px;
    position: relative;
}
.timeline-indicator-child-container-lg{
    height: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    /* transform: translateY(-40%); */
}
.timeline-indicator-lg{
    width: 16px;
    height: 16px;
}
.timeline-indicator-lg img{
    height: 100%;
}
.timeline-image-container-lg{
    height: 450px;
    margin: 0px 20px;
}
.timeline-image-child-container-lg{
    position: relative;
    height: 450px;
}
.timeline-image-child-container-lg img{
    height: 100%;
    /* transform: translateY(-40%); */
    /* margin-top: 100px; */
}
.timeline-detail-info-and-btn-container-lg{
    width: 400px;
    height: 200px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 40px;
    justify-content: space-between;
}
.timeline-detail-info-conatiner-lg{
    /* height: 100px; */
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
}
.timeline-detail-info-conatiner-lg h2{
    position: absolute;
    height: 200px;
    font-size: 40px;
    font-family: Oxanium-Bold;
    color: #232C60;
    text-transform: uppercase;
    transform: translateY(-80%);
}
.timeline-detail-info-conatiner-lg h1{
    font-size: 24px;
    font-family: Oxanium-Bold;
    color: #fff;
}
.timeline-detail-info-conatiner-lg p{
    font-size: 18px;
    font-family: NunitoSans_7pt-Regular;
    color: #fff;
}
.timeline-navigator-btn-container-lg{
    display: flex;
    gap: 40px;
    height: 32px;
    /* justify-content: center; */
    /* align-items: center; */
}
.timeline-prev-btn-lg{
    height: 32px;
    width: 32px;
}
.timeline-prev-btn-lg img {
    height: 100%;
}
.timeline-next-btn-lg{
    height: 32px;
    width: 32px;
}
.timeline-next-btn-lg img {
    height: 100%;
}
@media(max-width: 1320px){
    .timeline-carousel-container-lg{
        width: 900px;
        /* height: 500px; */
    }
    /* .timeline-image-container-lg{
        height: 500px;
        margin: 0px 20px;
    } */
    /* .timeline-image-child-container-lg{
        height: 500px;
    } */
    /* .timeline-image-child-container-lg img{
        height: 500px;
    } */
    .timeline-detail-info-and-btn-container-lg{
        width: 350px;
        height: 200px;
    }
    .timeline-detail-info-conatiner-lg{
        gap: 16px;
    }
    .timeline-detail-info-conatiner-lg h1{
        font-size: 24px;
    }
    .timeline-detail-info-conatiner-lg p{
        font-size: 18px;
    }
}
@media(max-width: 1020px){
    /* .timeline-wrapper-lg{
        padding-left: 40px;
        padding-top:  200px;
        padding-right: 40px;
        padding-bottom: 100px;
    } */
    .timeline-carousel-container-lg{
        width: 700px;
        height: 500px;
    }
    .timeline-indicator-container-lg{
        height: 350px;
    }
    .timeline-indicator-child-container-lg{
        height: 350px;
    }
    .timeline-image-container-lg{
        height: 350px;
        margin: 0px 20px;
    }
    .timeline-image-child-container-lg{
        height: 350px;
    }
    .timeline-image-child-container-lg img{
        height: 350px;
    }
    .timeline-detail-info-and-btn-container-lg{
        width: 280px;
        height: 170px;
    }
    .timeline-detail-info-conatiner-lg{
        gap: 16px;
    }
    .timeline-detail-info-conatiner-lg h1{
        font-size: 18px;
    }
    .timeline-detail-info-conatiner-lg p{
        font-size: 14px;
    }
}
@media(max-width: 960px){
    .timeline-wrapper-lg{
        padding: 72px 20px;
    }
    .timeline-detail-info-conatiner-lg h2{
        font-size: 32px;
    }
}
@media(max-width: 840px){
    /* .timeline-wrapper-lg{
        padding: 10px 20px;
    } */
    .timeline-carousel-container-lg{
        width: 600px;
        height: 400px;
    }
    .timeline-indicator-child-container-lg{
        gap: 8px;
    }
    /* .timeline-indicator-lg{
        width: 16px;
        height: 16px;
    }
    .timeline-image-container-lg{
        height: 300px;
        margin: 0px 20px;
    }
    .timeline-image-child-container-lg{
        height: 300px;
    }
    .timeline-image-child-container-lg img{
        height: 300px;
    } */
    .timeline-image-container-lg{
        margin: 0px 10px;
    }
    .timeline-detail-info-and-btn-container-lg{
        width: 250px;
        height: 180px;
    }
    .timeline-detail-info-conatiner-lg{
        gap: 16px;
    }
    .timeline-detail-info-conatiner-lg h2{
        height: 200px;
        transform: translateY(-60%);
    }
    .timeline-detail-info-conatiner-lg h1{
        font-size: 18px;
    }
    .timeline-detail-info-conatiner-lg p{
        font-size: 14px;
    }
}
@media(max-width: 680px){
    .timeline-carousel-container-lg{
        width: 500px;
        height: 400px;
    }
    .timeline-indicator-container-lg{
        height: 300px;
    }
    .timeline-indicator-child-container-lg{
        gap: 8px;
        height: 300px;
    }
    .timeline-indicator-lg{
        width: 12px;
        height: 12px;
    }
    .timeline-image-container-lg{
        height: 300px;
    }
    .timeline-image-child-container-lg{
        height: 300px;
    }
    .timeline-image-child-container-lg img{
        height: 300px;
    }
    .timeline-detail-info-and-btn-container-lg{
        width: 200px;
        height: 160px;
        margin: 20px;
    }
    .timeline-detail-info-conatiner-lg{
        gap: 16px;
    }
    .timeline-detail-info-conatiner-lg h1{
        font-size: 16px;
    }
    .timeline-detail-info-conatiner-lg p{
        font-size: 12px;
    }
    .timeline-prev-btn-lg{
        height: 24px;
        width: 24px;
    }
    .timeline-next-btn-lg{
        height: 24px;
        width: 24px;
    }
}
@media(max-width: 600px){
    .timeline-wrapper-lg h1{
        font-size: 24px;
    }
    .timeline-detail-info-conatiner-lg h1{
        font-size: 18px;
    }
    .timeline-detail-info-conatiner-lg p{
        font-size: 14px;
    }
}
@media(max-width: 600px){
    .timeline-wrapper-sm{
        width: 100vw;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 40px 0px;
    }
    .timeline-heading-sm{
        width: 100vw;
        text-align: center;
        font-size: 24px;
        font-family: Oxanium-Bold;
        color: #232C60;
        text-transform: uppercase;
    }
    .timeline-carousel-div-sm{
        display: flex;
        width: 250px;
        overflow-x: hidden;
        align-self: center;
    }
    .timeline-image-div-sm{
        width: 250px;
        display: flex;
        flex-direction: column;
        height: auto;
        transition: translate 300ms ease-in-out;
        scroll-behavior: smooth;
        scroll-snap-type: mandatory;
    }
    .timeline-image-sm{
        width: 250px;
    }
    .timeline-detail-sm{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        color: #232C60;
    }
    .timeline-detail-heading-sm{
        font-size: 16px;
        font-family: Oxanium-Bold;
        margin-top: 12px;
        width: 100%;
        /* text-align: center; */
    }
    .timeline-detail-work-sm{
        font-family: NunitoSans_7pt-Regular;
        font-size: 14px;
        width: 100%;
        /* text-align: center; */
    }
    .timeline-carousel-navigator-sm{
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .timeline-prev-btn{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: 20px;
    }
    .timeline-back-arrow-img{
        width: 40px;
    }
    .timeline-next-btn{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 20px;
    }
    .timeline-for-arrow-img{
        width: 40px;
    }
    .timeline-indicator-div-sm{
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
    }
    .timeline-circle-indicator-div-sm{
        height: 16px;
    }
    .timeline-circle-indicator-div-sm img {
        height: 100%;
    }
}