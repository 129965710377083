/*------------------------ remove default-margin ------------------------------*/
*{
  margin: 0;
}

/*----------------------- remove default-scrollbar ----------------------------*/
body::-webkit-scrollbar{
  display: none;  /* for chrome browser */
}
body{
  -ms-overflow-style: none; /* for edge browser */
}

/* ----------------------------------add fonts-------------------------------- */
/* @font-face {
  font-family: NunitoSans_7pt-Medium;
  src: url(/fonts/NunitoSans_7pt-Medium.ttf);
}
@font-face {
  font-family: Oxanium-Bold;
  src: url(/fonts/Oxanium-Bold.ttf);
} */

/* ----------------------error page customization----------------------------- */
#error-page-root {
  display: flex;
  background-image: url('../../assetsv1/images/page404/Page404BgImage.jpg');
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  gap: 20px;
}
#error-status{
  height: 100px;
}
#error-status img{
  height: 100px;
}
#error-message{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1vh;
  width: 90%;
  text-align: center;
}
#page-does-not-exist-message{
  font-family: 'NunitoSans_7pt-Medium';
  font-size: 20px;
  color: #fff;
  letter-spacing: normal;
  word-spacing: normal;
}
#redirecting-to-home-page-messsage{
  font-family: 'NunitoSans_7pt-Medium';
  font-size: 20px;
  color: #fff;
  letter-spacing: normal;
  word-spacing: normal;
}
#home-page-link-button{
  background-color: transparent;
  color: #fff;
  font-family: 'Oxanium-Bold';
  font-size: 20px;
  display: inline-block;
  margin: 40px;
  padding: 10px 20px;
  border: 2px solid #fff;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  text-decoration: none;
}
#home-page-link-button:hover{
  background-color: #fff;
  color: #fff;
  font-family: PromptSemiBold;
  font-size: 20px;
  display: inline-block;
  margin: 40px;
  padding: 10px 20px;
  color: #232C60;
  border: 2px solid #fff;
  text-decoration: none;
}
#home-page-link-button:active{
  background-color: #fff;
  color: #232C60;
  font-family: 'Oxanium-Bold';
  font-size: 20px;
  display: inline-block;
  margin: 40px;
  padding: 10px 20px;
  border: 2px solid #fff;
  text-decoration: none;
}