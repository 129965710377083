.gpr-brochure-container-outer-div{
    width: 100vw;
    height: auto;
    box-sizing: border-box;
    padding: 72px 0px;
    display: flex;
    flex-direction: column;
    background-color: #f7f5f5;
    color: #232C60;
    text-transform: uppercase;
}
.gpr-brochure-container-outer-div h1{
    font-family: Oxanium-Bold;
    font-size: 40px;
    width: 100vw;
    text-align: center;
    margin-bottom: 20px;
}
.gpr-brochure-container-inner-div{
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100vw;
    padding: 16px;
    gap: 200px;
    flex-flow: row wrap;
    /* justify-content: space-evenly; */
}
.gpr-brochure-item{
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    height: 14px;
}
.gpr-brochure-item img{
    height: 100%;
}
.gpr-brochure-item p{
    font-family: NunitoSans_7pt-Regular;
    font-size: 14px;
}
.gpr-brochure-item p:hover{
    text-decoration: underline;
}
@media(max-width: 960px){
    .gpr-brochure-container-outer-div{
        padding: 40px 0px;
    }
    .gpr-brochure-container-outer-div h1{
        font-size: 32px;
    }
}
@media(max-width: 640px){
    .gpr-brochure-container-outer-div h1{
        font-size: 28px;
    }
    .gpr-brochure-container-inner-div{
        gap: 32px
    }
}
@media(max-width: 600px){
    .gpr-brochure-container-outer-div h1{
        font-size: 24px;
    }
}
@media(max-width: 480px){
    .gpr-brochure-container-inner-div{
        flex-direction: column;
        align-self: center;
        width: auto;
    }
    .gpr-brochure-item{
        align-self: flex-start;
        margin-left: 20px;
        height: 24px;
    }
}