.sar-carousel-outer-div{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    background-color: #232C60;
    color: #fff;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 40px;
    box-sizing: border-box;
}
.sar-carousel-heading-container{
    width: 100vw;
    text-align: center;
}
.sar-carousel-heading{
    font-size: 40px;
    font-family: Oxanium-Bold;
    text-transform: uppercase;
}
.sar-carousel-inner-div{
    display: flex;
    width: 100vw;
    height: auto;
    background-color: #232C60;
    color: #fff;
    gap: 40px;
    align-items: center;
    justify-content: center;
}
.sar-carousel-prev-btn-container{
    width: 32px;
    height: 32px;
}
.sar-carousel-next-btn-container{
    width: 32px;
    height: 32px;
}
.sar-prev-btn{
    height: 100%;
    display: none;
}
.sar-next-btn{
    height: 100%;
    display: none;
}
.sar-carousel-div{
    display: flex;
    width: 1050px;
    overflow-x: hidden;
    /* gap: 32px; */
}
.sar-lg-child{
    width: 1050px;
    height: 405px;
    /* background-color: antiquewhite; */
    display: flex;
    gap: 32px;
    /* transition: translate 300ms ease-in-out; */
}
.sar-application{
    position: relative;
    width: calc(1050px/4);
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}
.sar-application-img{
    width: 100%;
}
.sar-application-title{
    position: absolute;
    color: #fff;
    font-size: 18px;
    font-family: Oxanium-Bold;
    bottom: 5%;
    transform: translateY(-5%);
    margin-left: 16px;
}
.sar-text-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.75);
    color: black;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  .sar-application:hover .sar-text-overlay{
    height: 100%;
  }
  .sar-application:hover .sar-application-title{
    color: transparent;
  }
  .sar-text-overlay-heading{
    font-size: 16px;
    font-family: Oxanium-Bold;
    align-self: flex-start;
    margin-left: 22px;
    margin-bottom: 16px;
    margin-top: 80px;
  }
  .sar-text-overlay-description{
    font-size: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: NunitoSans_7pt-SemiBold;
    margin-right: 12px;
    align-self: flex-start;
  }
  @media (max-width: 1220px){
    .sar-prev-btn{
        display: unset;
    }
    .sar-next-btn{
        display: unset;
    }
    .sar-carousel-div{
        width: 700px;
    }
    .sar-lg-child{
        width: 700px;
        height: 570px;
        transition: translate 300ms ease-in-out;
    }
    .sar-application{
        width: calc(700px/2);
        height: 100%;
    }
    .sar-text-overlay-heading{
        font-size: 16px;
    }
      .sar-text-overlay-description{
        font-size: 14px;
        margin-right: 12px;
    }
  }
  @media(max-width: 960px){
    .sar-carousel-heading{
        font-size: 32px;
    } 
    .sar-carousel-inner-div{
        gap: 20px;
    }
    .sar-carousel-div{
        width: 580px;
        height: auto;
        display: flex;
        overflow-x: hidden;
    }
    .sar-lg-child{
        display: flex;
        width: 580px;
        height: auto;
        gap: 16px;
        transition: translate 300ms ease-in-out;
    }
    .sar-application{
        position: unset;
        width: calc(((580-32)px)/3);
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .sar-application-img{
        width: 100%;
        margin-bottom: 16px;
    }
    .sar-application-title{
        display: none;
    }
    .sar-text-overlay{
        position: unset;
        bottom: unset;
        left: unset;
        right: unset;
        display: flex;
        flex-direction: column;
        color: #232C60;
        width: calc(((580-32)px)/3);
        height: unset;
    }
    .sar-text-overlay-heading{
        font-family: Oxanium-Bold;
        font-size: 18px;
        margin-top: unset;
        margin-left: 24px;
    }
    .sar-text-overlay-description{
        font-family: NunitoSans_7pt-SemiBold;
        font-size: 14px;
        margin-bottom: 16px;
        margin-right: 12px;
    }
  }
  @media(max-width: 740px){
    .sar-carousel-div{
        width: 480px;
    }
    .sar-lg-child{
        width: 480px;
    }
    .sar-application{
        width: calc(((480-32)px)/3);
    }
    .sar-text-overlay{
        width: calc(((480-32)px)/3);
    }
    .sar-text-overlay-heading{
        font-size: 18px;
        margin-left: 24px;
    }
    .sar-text-overlay-description{
        font-size: 14px;
        margin-bottom: 16px;
        margin-right: 12px;
    }
  }
  @media (max-width: 640px){
    .sar-carousel-div-1{
        display: flex;
        width: 320px;
        overflow: hidden;
    }
    .sar-lg-child-1{
        display: flex;
        gap: 16px;
        width: 320px;
        height: auto;
        gap: 0px;
        transition: translate 300ms ease-in-out;
    }
    .sar-application-1{
        display: flex;
        flex-direction: column;
        /* position: relative; */
        width: 380px;
        height: 100%;
        background-color: #fff;
    }
    .sar-application-img-1{
        width: 320px;
    }
    .sar-text-overlay-1 {
        background-color: #fff;
        color: #232C60;
        overflow: hidden;
        width: 100%;
        height: auto;
        transition: 0.5s ease;
        display: flex;
        flex-direction: column;
      }
      .sar-text-overlay-heading-1{
        font-size: 18px;
        font-family: Oxanium-Bold;
        align-self: flex-start;
        margin: 18px;
      }
      .sar-text-overlay-description-1{
        font-size: 14px;
        font-family: NunitoSans_7pt-SemiBold;
        margin-right: 12px;
        margin-bottom: 16px;
      }
  }
  /* @media (max-width: 640px){
    .sar-carousel-div-1{
        width: 320px;
    }
    .sar-lg-child-1{
        width: 320px;
        height: auto;
    }
    .sar-application-1{
        width: 320px;
        height: 100%;
    }
    .sar-application-img-1{
        width: 320px;
    }
      .sar-text-overlay-heading-1{
        font-size: 18px;
      }
      .sar-text-overlay-description-1{
        font-size: 14px;
      }
  } */
@media(max-width: 600px){
    .sar-carousel-heading{
        font-size: 24px;
    }  
}
  @media (max-width: 460px){
    .sar-carousel-div-1{
        width: 65vw;
    }
    .sar-lg-child-1{
        width: 65vw;
        height: auto;
    }
    .sar-application-1{
        width: 65vw;
        height: 100%;
    }
    .sar-application-img-1{
        width: 65vw;
    }
      /* .sar-text-overlay-heading-1{
        font-size: 16px;
      }
      .sar-text-overlay-description-1{
        font-size: 12px;
      } */
  }
 